<!--
 * @Author: your name
 * @Date: 2021-03-23 17:28:10
 * @LastEditTime: 2021-08-20 10:21:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\Common\Class\Class.vue
-->
<template>
    <div class="content-class">
        <div class="class-line">
            <span class='class-blod'>类型：</span>
            <div class='filter' :class='{"menu-active": index == typeFlag}' v-for='(item, index) in classList'  @click="onClickType(item,index)" :key="index">
                <span>
                    {{ item.Name }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['classList'],
    data() {
        return {
            typeFlag:0,
        }
    },
    computed:{
    },
    watch:{
    },
    methods: {
        onClickType(item,index){
           this.typeFlag = index
           let classId = {
               atItemIdx:this.classList[this.typeFlag].Idx,
           }
           this.$emit('updateClassId',classId)
        }
    },
    created(){
    }
}
</script>

<style scoped>
.content-class{
    padding: 14px 20px;
    border: 1px solid #D4DFE7;
    background: #fff;
}
.res-box-select{
    display: inline-block;
}
.is-attach-type .forbid{
    color: #ccc;
}
.class-blod{
    cursor:default;
}
.filter:hover{
    color: #22B5AD !important;
}
</style>
