<!--
 * @Author: your name
 * @Date: 2021-03-23 17:28:10
 * @LastEditTime: 2021-09-26 13:47:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\Common\Class\Class.vue
-->
<template>
    <div class="content-class">
        <div class="class-line">
            <span class='class-blod'>环节：</span>
            <div class='filter' :class='{"menu-active": item.Idx == scType}' v-for='(item, index) in classList' @click="onClickHuanjie(item,index)" :key="index">
                <span>
                    {{ item.Name }}
                </span>
            </div>
        </div>
        <div class="class-line" >
            <span class='class-blod'>类型：</span>
            <div class='filter' :class='{"menu-active": item.Idx === atid || isAll(item)}' v-for='(item, index) in getTypeList'  @click="onClickType(item,index)" :key="index">
                <span>
                    {{ item.Name }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['classList','scType','atid'],
    data() {
        return {
        }
    },
    computed:{
        getTypeList(){
            let typeList = []
            this.classList.forEach(element => {
                if(element.Idx == this.scType){
                    typeList = element.ChildList
                }
            });
            return typeList
        },
    },
    watch:{},
    methods: {
        onClickHuanjie(item,index){
            let classId = {
                ScType:item.Idx,
                ATID:item.ChildList[1].Idx
            }
            this.updateClassId(classId)           
        },
        onClickType(item,index){
            let classId = {
                ScType:this.scType,
                ATID:item.Idx
            }
            if(index == 0){
                let idArr = []
                this.classList.forEach((item)=>{
                    if(item.Idx == this.scType){
                        item.ChildList.forEach(element => {
                            if(element.Idx!=-1){
                                idArr.push(element.Idx)
                            }
                        });
                    }
                })
                classId.ATID = idArr
            }
            this.updateClassId(classId)
        },
        updateClassId(classId){
            this.$emit('updateClassId',classId)
        },
        isAll(item){
            return item.Idx== -1 && typeof(this.atid) == 'object'
        }
    },
    created(){
    },
    mounted(){
        let classId = {
            ScType:this.scType,
            ATID:this.atid,
        }
        if(typeof(this.atid) == 'object'){
            let idArr = []
            this.classList.forEach((item)=>{
                if(item.Idx == this.scType){
                    item.ChildList.forEach(element => {
                        if(element.Idx!=-1){
                            idArr.push(element.Idx)
                        }
                    });
                }
            })
            classId.ATID = idArr
        }
        classId.init = true
        this.updateClassId(classId)
    }
}
</script>

<style scoped>
.content-class{
    padding: 14px 20px;
    border: 1px solid #D4DFE7;
    background: #fff;
}
.res-box-select{
    display: inline-block;
}
.is-attach-type .forbid{
    color: #ccc;
}
.class-blod{
    cursor:default;
}
.filter:hover{
    color: #22B5AD !important;
}
</style>
