<!--
* @Author: your name
* @Date: 2021-04-07 09:22:20
 * @LastEditTime: 2021-08-20 10:09:42
 * @LastEditors: Please set LastEditors
* @Description: In User Settings Edit
* @FilePath: \test\src\components\res\LectureResources\sun-menu.vue
-->
<template>
<div class='SunMenu'>
        <div class="tree-header">
            <div class="text-box" @click="onClickTreeHead">{{title}}<i class="el-icon-arrow-down"></i></div>
        </div>
        <tree-index 
            v-if="isValidArray(menuList)&&openGroup&&onCheckedItem&&!noData"
            :menuList='menuList' 
            :defaultOpenGroup="openGroup"
            :defaultonCheckedItem="onCheckedItem"
            @getMenuData="getMenuData"
        >
            <!-- <div class="big-icon"></div> -->
        </tree-index>
        <div class="noData" v-if="noData">暂无数据</div>
        <head-modal  v-show="showHeadModal&&loadAccount().role == '教师'"  :transitionList="transitionList" @closeHeadModal="closeHeadModal" @updateTitle="updateTitle" @getMenuList ="getMenuList" @updateDescribe="updateDescribe" class="head-modal-warp"></head-modal>
        <head-modal-stu  v-show="showHeadModal&&loadAccount().role == '学生' "  @closeHeadModal="closeHeadModal" @updateTitle="updateTitle" @getMenuList ="getMenuList" class="head-modal-warp"></head-modal-stu>
        <div class="cover" v-if="showHeadModal" @click="onCoverClick"></div>
</div>
</template>
<script>
import TreeIndex from '../SunNewComponents/NewTree/TreeIndex'
import HeadModal from './HeadModal'
import HeadModalStu from './HeadModalStu'
export default {
    name: 'SunMenu',
    props:['menuList','transitionList'],
    components: {TreeIndex,HeadModal,HeadModalStu},
    data () {
        return {
            showHeadModal:false,
            title:'',
            openGroup:null,
            onCheckedItem:null,
            noData:false
        }
    },
    computed: {},
    watch: {
        menuList:{
            handler:function(nVal){
                //刷新或切换版本模块后重置缓存数据
                this.openGroup = [this.menuList[0].Idx]
                this.onCheckedItem = this.menuList[0].Idx
                localStorage.setItem('menu_opened',JSON.stringify(this.openGroup))
                localStorage.setItem('menu_checked',JSON.stringify(this.onCheckedItem))
                let idList = this.getIdList(this.openGroup,this.onCheckedItem)
                localStorage.setItem('menu_idList',JSON.stringify(idList))
                let obj = {
                    item:this.menuList[0],
                    level:1
                }
                localStorage.setItem('menu_nodeData',JSON.stringify(obj))
                this.$emit('onMenuChanged')
            }
        }
    },
    created () {
    },
    mounted () {
        //首次进入加载&设置默认缓存数据
        let versionIndex = localStorage['versionIndex']
        let modelIndex = localStorage['modelIndex']
        if(versionIndex&&modelIndex&&versionIndex<this.transitionList.length&&modelIndex<this.transitionList[parseInt(versionIndex)].ChildList.length){
            this.title = this.transitionList[parseInt(versionIndex)].Name+' / '+this.transitionList[parseInt(versionIndex)].ChildList[parseInt(modelIndex)].Name 
        }else{
            this.title = this.transitionList[0].Name+' / '+this.transitionList[0].ChildList[0].Name 
        }
        let opened = localStorage['menu_opened']
        if(opened){
            //检查记忆的数据是否存在
            let str = JSON.stringify(this.menuList)
            for (let i = 0; i < opened.length; i++) {
                const element = opened[i];
                if(str.indexOf(element)==-1){
                    opened = false
                }
            }
            opened = JSON.parse(opened)
            this.openGroup = opened
        }else{
            if(this.menuList[0].ChildList.length>0){
                this.openGroup = [this.menuList[0].Idx]
            }else{
                this.openGroup = []
            }
            localStorage.setItem('menu_opened',JSON.stringify(this.openGroup))
        }

        let checked = localStorage['menu_checked']
        checked = this.checkLocalData(checked)
        if(checked){
            checked = JSON.parse(checked)
            this.onCheckedItem = checked
        }else{
            this.onCheckedItem = this.menuList[0].Idx
            localStorage.setItem('menu_checked',JSON.stringify(this.onCheckedItem))
        }
        let idList = this.getIdList(this.openGroup,this.onCheckedItem)
        localStorage.setItem('menu_idList',JSON.stringify(idList))
        let nodeData = localStorage['menu_nodeData']
        if(!nodeData){
            let obj = {
                item:this.menuList[0],
                level:1
            }
            localStorage.setItem('menu_nodeData',JSON.stringify(obj))
        }
        // this.$emit('onMenuChanged')
    },
    methods: {
            onClickTreeHead(){
                this.showHeadModal = true
            },
            closeHeadModal(){
                this.showHeadModal = false
            },
            updateTitle(data){
                this.title = data
            },
            getMenuList(id){
                let that = this
                this.$emit('getMenuList',id,'SunMenu')
            },
            onCoverClick(){
                this.showHeadModal = false
            },
            getMenuData(data,opened,checked){
                this.onCheckedItem = checked
                this.openGroup = opened
                let idList = this.getIdList(opened,checked)
                localStorage.setItem('menu_checked',JSON.stringify(checked))
                localStorage.setItem('menu_opened',JSON.stringify(opened))
                localStorage.setItem('menu_idList',JSON.stringify(idList))
                localStorage.setItem('menu_nodeData',JSON.stringify(data))
                this.$emit('onMenuChanged')
            },
            getIdList(opened,checked){//拼接id数组
                let idList = []
                idList = idList.concat(opened)
                idList.push(checked)
                return Array.from(new Set(idList))
            },
            updateDescribe(data){
                this.$emit('updateDescribe',data)
            },
            checkLocalData(id){
                let str = JSON.stringify(this.menuList)
                if(str.indexOf(id)==-1){
                    return false
                }else{
                    return id
                }
            }

    }
}
</script>

<style lang='less' scoped>
.SunMenu{
    position:relative;

}
.tree-header{
    background: #FFF;
    padding: 18px 18px 0 18px;
    .text-box{
        position: relative;
        border-radius: 2px;
        border: 1px solid #CBCBCB;
        height: 30px;
        line-height: 30px;
        padding: 0 30px 0 10px;
        color: #757575;
        white-space:nowrap;
        text-overflow:ellipsis; 
        overflow:hidden;
        cursor: pointer;
        user-select: none;
        i{
            position:absolute;
            right: 13px;
            top: 8px;
        }
    }
}
.head-modal-warp{
    position: fixed;
    top: 0;
    left: 410px;
    width: 60vw;
    min-height: 128px;
    background-color: #FFF;
    z-index: 100;
    box-shadow: 0 2px 8px 2px rgb(142 142 142 / 15%);
    transition: all 0.8s ease 0s;
    padding: 0 12px;
    padding-top: 16px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
    // height: 100%;
    max-height: 70vh;
    overflow: auto;
}
.big-icon{
    display: inline-block;
    margin-left: 5px;
    width: 51px;
    height: 22px;
    background-image: url('/static/images/res/sun-kczx/BigUnit.png');
    background-size: 100%;
}
.cover{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
.noData{
    height: 100vh;
    line-height: 80vh;
    text-align: center;
}
</style>
