<!--
 * @Author: your name
 * @Date: 2021-04-07 09:43:33
 * @LastEditTime: 2021-08-20 10:09:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\LectureResources\HeadModal.vue
-->
<template>
  <div class='HeadModalStu'>
      <div class="subject">
          <span class="title">学科:</span>
          <div class="list-box">
                <div class="list-item" 
                    :class="{isActive:index==subjectIndex}" 
                    @click="onClickSubject(item,index)" 
                    v-for="(item,index) in subjectList" 
                    :key="index"
                >{{item.SubjectName}}</div>
          </div>
      </div>
      <div class="version">
          <span class="title">版本:</span>
          <div class="list-box">
                <div class="list-item" 
                    :class="{isActive:index==versionIndex}" 
                    @click="onClickVersion(item,index)" 
                    v-for="(item,index) in versionList" 
                    :key="index"
                >{{item.Name}}</div>
          </div>
      </div>
      <div class="model" v-show="modelList.length>0">
          <span class="title">模块:</span>
          <div class="list-box">
                <div class="list-item" 
                    :class="{isActive:index==modelIndex}" 
                    @click="onClickModel(item,index)" 
                    v-for="(item,index) in modelList" 
                    :key="index"
                >{{item.Name}}</div>
          </div>
      </div>
      <div class="model" v-show="ceciList.length>1">
          <span class="title">册次:</span>
          <div class="list-box">
                <div class="list-item" 
                    :class="{isActive:index==ceciIndex}" 
                    @click="onClickCeci(index)" 
                    v-for="(item,index) in ceciList" 
                    :key="index"
                >{{item.Name}}</div>
          </div>
      </div>
      <!-- <div class="btn-box">
          <div class="cancel" @click="onClickCancel">取消</div>
          <div class="submit" @click="onClickSubmit">确认</div>
      </div> -->
  </div>
</template>
<script>

export default {
    name: 'HeadModalStu',
    props:[],
    components: {},
    data () {
        return {
            subjectIndex:0,
            versionIndex:0,
            modelIndex:0,
            ceciIndex:0,
            ceciList:[],//册次列表
            subjectList:[],//学科列表
            versionList:[],//版本列表
            modelList:[],//模块列表
        }
    },
    computed: {},
    watch: {
    },
    created () {
    },
    mounted () {
        if(this.loadAccount().role == '学生'){
            this.getSubjectList()
        }
    },
    methods: {
        onClickSubject(item,index){
            this.subjectIndex = index
            //初始化数据
            this.versionList = []
            this.modelList = []
            this.ceciList = []
            this.versionIndex = -1
            this.modelIndex = -1
            this.ceciIndex = -1
            this.getVersionList()
        },
        onClickVersion(item,index){
            this.versionIndex = index
            this.modelList = []
            this.ceciList = []
            this.modelIndex = -1
            this.ceciIndex = -1
            item.ChildList? this.modelList = item.ChildList : this.modelList = []
            //如果册次只有一个则默认选中那一个册次 否则 不选中
            if(this.ceciList.length ==1){
                this.ceciIndex = 0
            }else{
                this.ceciIndex = -1
            }
        },
        onClickModel(item,index){
            this.modelIndex = index
            this.ceciList = []
            this.ceciIndex = -1
            item.ChildList? this.ceciList = item.ChildList : this.ceciList = []
            if(this.ceciList.length ==1){
                this.ceciIndex = 0
                this.onClickSubmit()
            }else{
                this.ceciIndex = -1
            }
        },
        onClickCeci(index){
            this.ceciIndex = index
            this.ceciList
            this.onClickSubmit()
        },
        onClickCancel(){
            this.$emit('closeHeadModal')
        },
        onClickSubmit(){
            let title = this.subjectList[this.subjectIndex].SubjectName +' / '+ this.versionList[this.versionIndex].Name +'/'+ this.modelList[this.modelIndex].Name  
            this.$emit('updateTitle',title)
            this.$emit('closeHeadModal')
            this.$emit('getMenuList',this.ceciList[this.ceciIndex].Idx)
            // let title = that.subjectList[that.subjectIndex].SubjectName +'/'+ that.versionList[that.versionIndex].Name +'/'+ that.modelList[that.modelIndex].Name  
            // this.$emit('updateTitle',title)
        },
        getSubjectList(){
            let that = this
            //学生获取学科 版本 模块
            let api = `/api/TextBook/GetStudentTextBookList?PeriodID=${this.getSunAccount('sc_period')}&ZoneID=${this.getSessionVal("sc_zone")}`
            this.startLoading()
            this.$http.get(this.getMasterDataCenterUrl() + api).then(res=>{
                this.stopLoading()
                if(res.data.data){
                    let data = res.data.data
                    for (let i = 0; i < data.length; i++) {
                        data[i] = this.toUpperCase(data[i])
                    }
                    this.subjectList = data
                    this.getVersionList(function(data){
                        that.versionList = data
                        that.versionIndex = 0
                        that.modelList = that.versionList[0].ChildList
                        that.modelIndex = 0
                        that.ceciList = that.modelList[0].ChildList
                        that.ceciIndex = 0
                        that.onClickSubmit();
                    })
                }
            })
        },
        getVersionList(callback){
            let that = this
            let subjectId = this.subjectList[this.subjectIndex].SubjectID
            let api = `/api/TextBook/GetSystemTextBookUnitList?PeriodID=${this.getSunAccount('sc_period')}&ZoneID=${this.getSessionVal("sc_zone")}&SubjectID=${subjectId}`
            this.startLoading()
            this.$http.get(this.getMasterDataCenterUrl() + api).then(res=>{
                this.stopLoading()
                if(this.isValidArray(res.data.data)){
                    let data = res.data.data
                    for (let i = 0; i < data.length; i++) {
                        data[i] = this.toUpperCase(data[i])
                        for (let j = 0; j < data[i].ChildList.length; j++) {
                            const element = data[i].ChildList[j];
                            element = this.toUpperCase(element)
                            for (let k = 0; k < element.ChildList.length; k++) {
                                const element2 = element.ChildList[k];
                                element2 = this.toUpperCase(element2)
                            }
                        }
                    }
                    if(callback){//首次进入页面时
                        callback(data)
                    }else{
                        this.versionList = data
                    }
                }else{
                    this.versionList = []
                    this.modelList = []
                    this.ceciList = []
                    this.versionIndex = -1
                    this.modelIndex = -1
                    this.ceciIndex = -1
                }
            })
        }
}
}
</script>

<style lang='less' scoped>
.title{
    display: inline-block;
    min-width: 70px;
    padding-left: 13px;
    font-size: 14px;
    color: #4A4A4A; 
}
.subject,.version,.model{
    display: flex;
}
.list-box{
    display:flex;
    flex-wrap: wrap;
    padding:0;
}
.list-item{
    padding: 0px 10px;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    margin-bottom: 20px;
}
.isActive{
    color: #22B5AD;
}
.btn-box{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    div{
        margin-right: 18px;
        border: 1px solid #06C1AE;
        background-color: #fff;
        color: #22B5AD;
        border-radius: 15px;
        padding: 5px 20px;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
    }
    .submit{
        background-color: #06C1AE;
        color:#fff;
    }
}
</style>
