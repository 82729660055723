<!--
 * @Author: your name
 * @Date: 2021-04-07 09:43:33
 * @LastEditTime: 2021-08-20 10:09:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\LectureResources\HeadModal.vue
-->
<template>
  <div class='HeadModal'>
      <div class="version">
          <span class="title">版本:</span>
          <div class="version-box">
                <div class="version-item" 
                    :class="{isActive:index==versionIndex}" 
                    @click="onClickVersion(item,index)" 
                    v-for="(item,index) in transitionList" 
                    :key="index"
                >{{item.Name}}</div>
          </div>
      </div>
      <div class="model">
          <span class="title">模块:</span>
          <div class="model-box">
                <div class="model-item" 
                    :class="{isActive:index==modelIndex}" 
                    @click="onClickModel(item,index)" 
                    v-for="(item,index) in modelList" 
                    :key="index"
                >{{item.Name}}</div>
          </div>
      </div>
      <div class="model" v-show="ceciList.length>1">
          <span class="title">册次:</span>
          <div class="model-box">
                <div class="model-item" 
                    :class="{isActive:index==ceciIndex}" 
                    @click="onClickCeci(index)" 
                    v-for="(item,index) in ceciList" 
                    :key="index"
                >{{item.Name}}</div>
          </div>
      </div>
      <!-- <div class="btn-box">
          <div class="cancel" @click="onClickCancel">取消</div>
          <div class="submit" @click="onClickSubmit">确认</div>
      </div> -->
  </div>
</template>
<script>

export default {
    name: 'HeadModal',
    props:['transitionList'],
    components: {},
    data () {
        return {
            versionIndex:0,
            modelIndex:0,
            ceciIndex:0,
            ceciList:[],//册次列表
            modelList:[],//模块列表
        }
    },
    computed: {},
    watch: {
        transitionList:{
            handler:function(nVal) {
                let versionIndex = parseInt(localStorage.getItem('versionIndex')) 
                let modelIndex = parseInt(localStorage.getItem('modelIndex'))
                let ceciIndex = parseInt(localStorage.getItem('ceciIndex'))
                versionIndex = this.checkLocalData(versionIndex,nVal)
                modelIndex = this.checkLocalData(modelIndex,nVal[versionIndex].ChildList)
                ceciIndex = this.checkLocalData(ceciIndex,nVal[versionIndex].ChildList[modelIndex].ChildList)
                if(!isNaN(versionIndex) && !isNaN(modelIndex) && !isNaN(ceciIndex)){
                    this.modelList = nVal[versionIndex].ChildList
                    this.ceciList = nVal[versionIndex].ChildList[modelIndex].ChildList
                    this.versionIndex = versionIndex
                    this.modelIndex = modelIndex
                    this.ceciIndex = ceciIndex
                    let title = this.transitionList[versionIndex].Name+' / '+this.transitionList[versionIndex].ChildList[modelIndex].Name  
                    this.$emit('updateTitle',title)
                }else if(nVal&&nVal.length>0&&nVal[0].ChildList){
                    //初始模块列表
                    this.modelList = nVal[0].ChildList
                    this.modelIndex = 0
                    if(nVal[0].ChildList.length>0){
                        this.ceciList = nVal[0].ChildList[0].ChildList
                        this.ceciIndex = 0
                    }
                    let title = this.transitionList[0].Name+' / '+this.transitionList[0].ChildList[0].Name  
                    this.$emit('updateTitle',title)
                }
                if(nVal&nVal[0].ChildList.length == 0 ){
                    this.modelList = []
                    this.ceciList = []
                    this.modelIndex = 0
                    this.ceciIndex = 0
                }
            },
            immediate:true,
            deep: true
        },
    },
    created () {
    },
    mounted () {
    },
    methods: {
        onClickVersion(item,index){
            item.ChildList? this.modelList = item.ChildList : this.modelList = []
            this.versionIndex = index
            this.modelIndex = -1
            this.ceciList = []
            this.ceciIndex = -1
            //如果册次只有一个则默认选中那一个册次 否则 不选中
            if(this.ceciList.length ==1){
                this.ceciIndex = 0
            }else{
                this.ceciIndex = -1
            }
        },
        onClickModel(item,index){
            item.ChildList? this.ceciList = item.ChildList : this.ceciList = []
            this.modelIndex = index
            if(this.ceciList.length ==1){
                this.ceciIndex = 0
                this.onClickSubmit()
            }else{
                this.ceciIndex = -1
            }
        },
        onClickCeci(index){
            this.ceciIndex = index
            this.ceciList
            this.onClickSubmit()
        },
        onClickSubmit(){
            localStorage.setItem('versionIndex',this.versionIndex)
            localStorage.setItem('modelIndex',this.modelIndex)
            localStorage.setItem('ceciIndex',this.ceciIndex)
            let title = this.transitionList[this.versionIndex].Name+' / '+this.modelList[this.modelIndex].Name 
            this.$emit('updateTitle',title)
            this.$emit('closeHeadModal')
            this.$emit('getMenuList',this.ceciList[this.ceciIndex].Idx)
            this.$emit('updateDescribe',this.ceciList[this.ceciIndex].Describe)
        },
        //检查记忆的数据是否合法
        checkLocalData(data,list){
            if(data&&data<list.length){
                return data
            }else{
                return 0
            }
        }
}
}
</script>

<style lang='less' scoped>
.title{
    display: inline-block;
    min-width: 70px;
    padding-left: 13px;
    font-size: 14px;
    color: #4A4A4A; 
}
.version,.model{
    display: flex;
}
.version-box,.model-box{
    display:flex;
    flex-wrap: wrap;
    z-index: 1;
}
.version-item,.model-item{
    padding: 0px 10px;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    color: #4A4A4A; 
    margin-bottom: 20px;
}
.isActive{
    color: #22B9B1;
}
.btn-box{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    div{
        margin-right: 18px;
        border: 1px solid #06C1AE;
        background-color: #fff;
        color: #22B5AD;
        border-radius: 15px;
        padding: 5px 20px;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
    }
    .submit{
        background-color: #06C1AE;
        color:#fff;
    }
}
</style>
