<!--
 * @Author: your name
 * @Date: 2021-04-02 11:17:13
 * @LastEditTime: 2021-06-16 10:00:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\SunNewComponents\NewTree\TreeIndex.vue
-->
<template>
  <div class="TreeIndex">
      <new-tree 
      :menuList='menuList' 
      :onCheckedItem="onCheckedItem"
      :openGroup="openGroup"
      :level="1"
      >
        <!-- <slot></slot> -->
      </new-tree>
  </div>
</template>
<script>
import NewTree from './NewTree'
import {TreeBus} from './TreeBus'
export default {
  name: 'TreeIndex',
  props:['menuList','defaultOpenGroup','defaultonCheckedItem'],
  components: {NewTree},
  data () {
    return {
        openGroup:[],
        onCheckedItem:null,
    }
  },
  computed: {},
  watch: {
    menuList:{
        handler:function(nVal){
            if(this.isValidArray(nVal)){
            }
        },
        immediate:true
    },
    defaultOpenGroup:{
        handler:function(nVal){
            if(this.isValidArray(nVal)){
                this.openGroup = nVal
            }
        },
        immediate:true
    },
    defaultonCheckedItem:{
        handler:function(nVal){
            if(this.isValid(nVal)){
                this.onCheckedItem = nVal
            }
        },
        immediate:true
    }
  },
  created () {

  },
  mounted () {
    TreeBus.$on('onClickMenuItem', item => {
        this.onClickMenuItem(item)
    })
    $('.menu-tree-item.isChecked').click()
  },
  methods: {
      onClickMenuItem(data){
            this.onCheckedItem = data.item.Idx //设置当前选择节点id
            if(data.item.ChildList.length>0){//展开和收起功能
                if(this.openGroup.indexOf(data.item.Idx) != -1){//当前点击节点是否在openGroup中
                    //收起item
                    let end = this.openGroup.length-(data.level-1)
                    this.openGroup.splice(data.level-1,end)//删除当前节点id和所有展开的子节点id
                }else{
                    //展开item
                    let length = this.openGroup.length-data.level //数组需要减去的长度,数组长度应和level保持一致
                    this.$set(this.openGroup,data.level-1,data.item.Idx)
                    this.openGroup.splice(data.level,length)
                }
            }else if(data.level == 1){
                //1级目录无子目录时清空数组
                this.openGroup = [] 
            }else if(data.level>1){
                //1级以下目录无子目录时收起痛击展开的目录
                this.openGroup.splice(data.level-1,1)
            }
            this.$emit('getMenuData',data,this.openGroup,this.onCheckedItem)
      },
  }
}
</script>

<style lang='less' scoped>
.TreeIndex{
    background: #FFF;
    padding: 18px 0px;
    padding-right: 18px;
}
</style>
