<template>
<!-- <Affix :offset-top="5" @on-change="change"> -->
    <div class="menu-container">
        <div class="menu-top" 
            v-if="!isPhone"
            @click='onMenuTopClick'>
            <span class='select-subject'>{{crumbs}}</span>
            <Icon :size='22' class='pull-right' color='#ffoverflow: hidden; white-space: nowrap; text-overflow: clip;width:100px;f' type="ios-arrow-right"></Icon>
        </div>
        <div class="menu-box" v-if="typeIdx == 0 && !isPhone">
            <i-menu ref='resMenu' class='chapter-item' v-if='menuList' width='auto' :accordion='true' 
            :open-names='openGroup' 
            :active-name='activeIdx'
            @on-select='clickMenuSection'
            @on-open-change='clickMenuSection'>
                <template v-for='(item,index1) in menuList' >
                    <MenuItem @click.native="onMenuItemClicked(item)" v-if='!isValidArray(item.ChildList)' :key="index1" :name="item.Idx">
                        <Icon color='#979797' type="ios-photos-outline"></Icon>
                        <el-tooltip  :disabled="item.Name.length > 11 ? false : true" effect="light" placement="top-start">
                            <div slot="content" v-html="item.Name"></div>
                            <span class="ivu-menu-span">
                                {{item.Name}}
                            </span>
                        </el-tooltip>
                        <div v-if="item.IsBigUnit"  class="big-icon"></div>
                    </MenuItem>
                    <Submenu @click.native="onMenuItemClicked(item)" v-if='isValidArray(item.ChildList)' :key="index1" :name="item.Idx">
                        <template slot="title" >
                            <Icon color='#979797' type="ios-photos-outline"></Icon>
                            <el-tooltip  :disabled="item.Name.length > 11 ? false : true" effect="light" placement="top-start">
                                <div slot="content" v-html="item.Name"></div>
                                <span class="ivu-menu-span">
                                        {{item.Name}}
                                </span>
                            </el-tooltip>
                            <div v-if="item.IsBigUnit"  class="big-icon"></div>
                        </template>
                        <template v-for='(itemTwo,index2) in item.ChildList'>
                            <MenuItem @click.native="onMenuItemClicked(itemTwo)" v-if='!isValidArray(itemTwo.ChildList)' :key="index2" :name="itemTwo.Idx">
                                <el-tooltip  :disabled="itemTwo.Name.length > 10 ? false : true" effect="light" placement="top-start">
                                    <div slot="content" v-html="itemTwo.Name"></div>
                                    <span class="ivu-menu-span">{{itemTwo.Name}}</span>
                                </el-tooltip>
                                <div v-if="item.IsBigUnit"  class="big-icon"></div>
                            </MenuItem>
                            <Submenu @click.native="onMenuItemClicked(itemTwo)" v-if='isValidArray(itemTwo.ChildList)' :key="index2" :name="itemTwo.Idx">
                                <template slot="title">
                                    <el-tooltip  :disabled="itemTwo.Name.length > 10 ? false : true" effect="light" placement="top-start">
                                        <div slot="content" v-html="itemTwo.Name"></div>
                                        <span class="ivu-menu-span">{{itemTwo.Name}}</span>
                                    </el-tooltip>
                                    <div v-if="item.IsBigUnit" class="big-icon"></div>
                                </template>
                                <template v-for='(itemThree,index3) in itemTwo.ChildList'>
                                    <MenuItem @click.native="onMenuItemClicked(itemThree)" :title='itemThree.Name' :key="index3" :name="itemThree.Idx">
                                        <el-tooltip  :disabled="itemThree.Name.length > 9 ? false : true" effect="light" placement="top-start">
                                            <div slot="content" v-html="itemThree.Name"></div>
                                            <span class="ivu-menu-span">{{itemThree.Name}}</span>
                                        </el-tooltip>
                                        <div v-if="item.IsBigUnit" class="big-icon"></div>
                                    </MenuItem>
                                </template>
                            </Submenu>
                        </template>
                    </Submenu>
                </template>
            </i-menu>
            <div v-else class='no-result'></div>
        </div>
        <!-- 点击目录出现的目录相关筛选 -->
        <transition name="slide-fade">
            <div class="menu-top-choice" v-if='menuChoiceStatus'  v-clickoutside="onHideSearchClick" :class="{'phone-catalog':isPhone}">
                <div v-if="bookFlag">
                    <div class="class-line">
                        <div class="class-blod">版本</div>
                        <br>
                        <span 
                            class="filter" 
                            :class="{'menu-active':item.ChildList == modelList}"
                            v-for="(item,index) in transitionList" 
                            :key="index"
                            @click="onClickVersion(item,index)"
                        >{{item.Name}}</span>
                    </div>
                    <div class="class-line">
                        <div class="class-blod">模块</div>
                        <br>
                        <span 
                            class="filter" 
                            :class="{'menu-active':index == modelActiveFlag}"
                            v-for="(item,index) in modelList" 
                            :key="index"
                            @click="onClickModel(index)"
                        >{{item.Name}}</span>
                    </div>
                    <div class='pull-right'>
                        <Button class='choice-btn' type="warning" @click="onClickSubmit">确定</Button>
                    </div>
                    <!-- <div class='pull-right' v-if="!isPhone">
                        <Button class='choice-btn' type="warning" @click='menuChoiceStatus = false'>确定</Button>
                    </div> -->
                </div>
            </div>
        </transition>
    </div>
<!-- </Affix> -->
</template>

<script>
const clickoutside = {
    bind(el, binding, vnode) {
        function documentHandler(e) {
            if(el.contains(e.target)) {
                return false
            }
            if(binding.expression) {
                binding.value()
            }
        }

        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
}
export default {
    components: {},
    directives: {clickoutside},
    props: {
        menuList: {
            type: Object,
            default: {}
        },
        transitionList: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: '请选择教材'
        },
        transitionKnowledgeList: {
            type: Array
        },
        knowledgeMenuList: {
            type: Array
        },
        isPhone:{
            type:Boolean
        }
    },
    filters: {
        filters(val) {
            if (val == 'Book') {
                return '册次'
            } else {
                return val
            }
        }
    },
    data() {
        return {
            menuChoiceStatus: false,
            openGroup:[],
            activeIdx:null,
            typeIdx: 0,
            bookFlag:true,
            // new
            modelList:[],//模块列表
            versionActiveFlag:0,//当前选中的版本
            modelActiveFlag:0,//当前选中的模块
            crumbs:'请选择教材',//当前选择的教材
            checkedMenuItem:null,//当前被选择目录的数据
        }
    },
    methods: {
        // 悬浮菜单的选择
        onMenuTopClick() {
            this.menuChoiceStatus = !this.menuChoiceStatus
            // this.$bus.emit('onMenuTopClick')
        },
        onHideSearchClick() {
            this.menuChoiceStatus = false
        },
        clickMenu(Idx) {
        },
        clickMenuSection(IdxGroup){
            let idArr = []
            idArr = idArr.concat(this.$refs.resMenu.openedNames)
            if(typeof IdxGroup == 'number'){
                idArr.push(IdxGroup)
            }
            this.getBigUnit(idArr)
            this.$emit('updateMenuIdArr',idArr)
            // this.$emit('getList',idArr)
        },
        onClickVersion(item,index){
            item.ChildList? this.modelList = item.ChildList : this.modelList = []
            this.versionActiveFlag = index
            this.modelActiveFlag = 0
        },
        onClickModel(index){
            this.modelActiveFlag = index
        },
        onClickSubmit(){
            this.menuChoiceStatus = false
            this.getCrumbs()//面包屑传给父组件保存起来
            this.$emit('getMenuList',this.modelList[this.modelActiveFlag].Idx)
        },
        getCrumbs(){
            this.crumbs = this.transitionList[this.versionActiveFlag].Name +' / '+this.modelList[this.modelActiveFlag].Name
            this.$emit('getCrumbs',this.crumbs)
        },
        setDefaultMenu(curr){//默认选择或展开第一个目录
            if(curr[0].ChildList.length>0){
                this.openGroup.push(curr[0].Idx)
                this.$nextTick(()=>{
                    this.$refs.resMenu.updateOpened()
                    this.getBigUnit(this.openGroup)
                    this.$emit('updateMenuIdArr',this.openGroup)
                    // this.$emit('getList',this.openGroup)
                })
            }else{
                this.activeIdx = curr[0].Idx
                this.$nextTick(()=>{
                    this.$refs.resMenu.updateActiveName()
                    let arr = []
                    arr.push(this.activeIdx)
                    this.getBigUnit(arr)
                    this.$emit('updateMenuIdArr',arr)
                    // this.$emit('getList',arr)
                })
            }
            
        },
        onMenuItemClicked(item){
            // console.log(item.Idx);
            // this.checkedMenuItem = item
        },
        getBigUnit(idArr){
            let length = idArr.length
            if(length==0)return
            if(length == 1){
                this.menuList.some(element => {
                    if(element.Idx == idArr[0]){
                        this.$emit('setClassStatus',element.IsBigUnit,element.Name)
                        return
                    }
                });
            }
            if(length == 2){
                this.menuList.some(element => {
                    if(element.Idx == idArr[0]){
                        element.ChildList.some(element => {
                            if(element.Idx == idArr[1]){
                                this.$emit('setClassStatus',element.IsBigUnit,element.Name)
                                return
                            }
                        });
                    }
                });
            }
            if(length == 3){
                this.menuList.some(element => {
                    if(element.Idx == idArr[0]){
                        element.ChildList.some(element => {
                            if(element.Idx == idArr[1]){
                                element.ChildList.some(element => {
                                    if(element.Idx == idArr[2]){
                                        this.$emit('setClassStatus',element.IsBigUnit,element.Name)
                                        return
                                    }
                                });
                            }
                        });
                    }
                });
            }
        }
    },
    watch:{
        menuList:{
            handler:function(curr) {
                this.openGroup=[]
                this.activeIdx =null
                if(this.isValidArray(curr)){
                    this.setDefaultMenu(curr)
                }
            },
            deep: true
        },
        transitionList:{
            handler:function(nVal) {
                if(nVal&&nVal[0].ChildList){
                    //初始模块列表
                    this.modelList = nVal[0].ChildList
                    //初始化面包屑
                    this.crumbs = nVal[0].Name +' / '+ nVal[0].ChildList[0].Name
                }
            },
            deep: true
        },
        checkedMenuItem:{
            handler:function(nVal) {
                if(this.isValid(nVal)){
                    // if(nVal.Idx == 32394){
                    //     this.$emit('setClassStatus',true)    
                    // }else{
                    //     this.$emit('setClassStatus',nVal.IsBigUnit)
                    // }
                }
            },
            deep:true,
        },
    },
    computed(){},
    created(){
    },
    mounted() {
    }
}
</script>

<style scoped>
.menu-container /deep/ .ivu-back-top{
    right: 0.4%!important;
}
.menu-box, .menu-box1{
    border-left: 1px solid #D4DFE7;
}
.menu-box1 /deep/ .ivu-menu-submenu-title{
    padding: 20px 10px;
}
.ivu-menu-opened li{
    background: #fff;
}
.choice-btn{
    width: 110px;
    border-radius: 20px;
}
.no-result{
    width: 100%;
    height:500px;
    line-height: 500px;
    background: url('/static/images/res/jy-nodata_sm.png') no-repeat 50% 40%;
    background-color: #fff;
    font-size:23px;
    text-align: center;
}
.ivu-menu-span{
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}
.select-subject{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width:200px;
    display: inline-block;
}
/* 添加知识点筛选 */
.choice-item{
    margin-right: 20px;
    display: inline-block;
    padding: 5px 18px 5px 18px;
    font-size: 14px;
    border-radius: 30px;
    cursor: pointer;
}
.item-active{
    background: #0EBAD7;
    color: #fff;
}
.phone-catalog{
    position: static;
    width: 100%;
    height: auto;
    border: none;
    border-bottom: 20px solid rgb(244,247,250);
}
.big-tip{
    background: #6DC6FF;
    border-radius: 2px;
    width: 4px;
    height: 20px;
    display: inline-block;
    margin-right: 12px;
}
.jiaocai-box{
    margin-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    height: 25px;
}
.jiaocai-span{
    font-size: 21px;
    color: #4A4A4A;
}
.book-flag-span{
    position: absolute;
    top: 3px;
    right: 5px;
}
.top-san{
    transition: transform .3s;
    transform: rotate(-180deg);     
}
.bottom-san{
    transition: transform .3s;
    transform: rotate(0deg);

}
.sanjiao-span{
	width:0;
    height:0;
	border-right:10px solid transparent;
	border-left:10px solid transparent;
	border-top:10px solid rgb(148,148,148);
    top:13px
}
.catlog-box{
    padding: 14px 20px;
    height: 45px !important;
    border-bottom: 1px solid #E9F8FF;
}
.catlog-flag-span{
    right: 25px;
    top: 13px;
}
.phone-menu-box{
    border-bottom: 20px solid rgb(244,247,250) !important;
}
.menu-container /deep/ .big-icon{
    display: inline-block;
    position: absolute;
    width: 51px;
    height: 22px;
    background-image: url('/static/images/res/sun-kczx/BigUnit.png');
    background-size: 100%;
    right: 45px;
}
</style>
