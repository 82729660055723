<!--
 * @Author: your name
 * @Date: 2021-03-24 15:35:30
 * @LastEditTime: 2022-01-21 17:33:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\LectureResources\TbkcIndex.vue
-->
<template>
  <div class='TbkcIndex' :class="{hideNav:loadAccount().role == '学生'}">
      <div class='res-box'>
        <new-preview @close='onClickCloseDetails'  :details='details' v-if='detailStatus'></new-preview>
        <!------------------ 左侧目录 ---------->
        <el-scrollbar class="scrollbar" v-show="isValidArray(transitionList)" >
            <div class="menu">
                <sun-menu v-if="isValidArray(menuList)&&isValidArray(transitionList)" ref="sunMenu" @getMenuList="getMenuList" :transitionList="transitionList" :menuList="menuList" @onMenuChanged="onMenuChanged" @updateDescribe="updateDescribe"></sun-menu>
            </div>
        </el-scrollbar>
        <div class="content">
            <!---------   右侧列表顶部的筛选 -------->
            <res-class
                v-if='!isBigUnit&&classList.length != 0&&loadAccount().role == "教师"&&isValidArray(menuList)'
                v-show="isValidArray(transitionList)"
                :classList='classList'
                :scType="scType"
                :atid="atid"
                @updateClassId="updateClassId"
                ref="resClass"
            ></res-class>
            <res-class-stu v-if="!isBigUnit && classList.length != 0&&loadAccount().role =='学生'" :classList='classList' @updateClassId="updateClassId"></res-class-stu>
            <div v-if="isBigUnit&&loadAccount().role == '教师'" class="bigTitle" :title="bigTitle">{{bigTitle}}</div>
            <res-list
                class="sun-res-list-box"
                :list='list'
                :data='data'
                :isSunPlatform="true"
                :isShowOrigin='false'
                :describe="describe"
                :showdescribe="scType==3"
                :atid="atid"
                @on-change-jx='clickJx'
                @on-change-crumb='clickCrumb'
                @open-shadow='openDetail'
                @on-change-page='changePage'
                @on-delete='deleteList'
                @on-search='search'
                @deleteCandidateSuccess="deleteCandidateSuccess"
                @onCollectedClick="onCollectedClick"
                @onScrollBottom="onScrollBottom"
                ref="resList"
                v-if="list.length>2&&loadAccount().role == '教师'"
                >
            </res-list>
            <res-list-stu v-if="list.length>2&&loadAccount().role == '学生'" :list='list' @open-shadow='openDetail' @onScrollBottom="onScrollBottom"></res-list-stu>
            <img v-if="!list||list.length<=2" class='no-result-img'  src="/static/images/res/no-resource1.png" alt="" />
        </div>
    </div>
    </div>
</template>
<script>
import resMenu from '@/components/res/Common/Menu/NewMenu'
import resClass from '@/components/res/Common/Class/NewClass'
import resClassStu from '@/components/res/Common/Class/NewClassStu'
import resList from '@/components/res/Common/List/NewList'
import TreeIndex from '../SunNewComponents/NewTree/TreeIndex'
import SunMenu from './SunMenu'
import ResListStu from '@/components/res/Common/List/ResListStu'
const NewPreview = resolve=> require.ensure([], () => resolve(require('@/components/res/Common/ResPreview/NewPreview')))
export default {
  name: 'TbkcIndex',
  props: ['Platform'],
  components: {resMenu,resClass,resList,TreeIndex,SunMenu,ResListStu,NewPreview,resClassStu},
  data () {
    return {
        // 菜单数据
        menuList:[],
        crumbs:'请选择教材',
        transitionList:[],
        // 分类数据
        classList: [],
        // 列表数据
        list: [],
        data: {
            index: 1,
                count: 10,
                keyword: '',
                order: 3
        },
        details: {},
        detailStatus: false,
        isBigUnit:null,
        bigTitle:'',
        scType:null,//环节id
        atid:null,//类型id
        atItemIdx:null,//学生角色类型id
        menuIdArr:[],//当前选中目录的id
        pageParam:{//页码参数
            pageIndex:1,
            pageSize:20
        },
        searchKeywords:'',//搜索栏关键词
        totalPage:1,
        classIsLeaf:false,//环节模块是否为叶子节点
        menuIsLeaf:false,//当前目录是否为叶子节点
        describe:'',//课后作业说明
        bookId:0,//当前目录id
        shareID: 2,
    }
  },
  computed: {
  },
  watch: {},
  beforeCreate(){
        //更换新用户后清空缓存数据
        let userId = this.loadAccount().userid
        let loginUserId = localStorage.loginUserId
        let subjectId = this.getSunAccount('sc_subject')
        let loginSubjectId = localStorage.subjectId 
        if(loginUserId){
            if(loginUserId != userId || subjectId != loginSubjectId){
                let keysToRemove = ['huanJieFlag','typeFlag','versionIndex','modelIndex','loginSession','menu_opened','menu_checked','menu_idList','menu_nodeData']
                for (const key of keysToRemove) {
                    localStorage.removeItem(key)
                }
            }
        }
        localStorage.setItem('loginUserId',userId)
        localStorage.setItem('subjectId',subjectId)
  },
  created () {
    if (this.$route.path == "/Res/Tbzy") this.shareID = 3;
  },
  mounted () {
        let role = this.loadAccount().role
        this.getUserOperation().then((res)=>{
            this.getTransitionList()
            if(role == '学生'){
                this.getSegment() //无动态显示功能的旧接口
            }
        })
  },
  methods: {
        getNewSegment(param){
            let api = '/api/CourseCenterService/GetSegmentList'
            this.$http.post(this.getResourceCenterBusinessUrl() + api,param).then(res=>{
                if(this.isValidArray(res.data.data)){
                    let data = res.data.data
                    if(data.length == 0)return
                    for (let i = 0; i < data.length; i++) {
                        if(!this.isValidArray(data[i].childList)){
                            data.splice(i,1)
                            i--
                            continue
                        }
                        data[i] = this.toUpperCase(data[i])
                        for (let j = 0; j < data[i].ChildList.length; j++) {
                            let element = data[i].ChildList[j];
                            element = this.toUpperCase(element)
                        }
                    }
                    if(param.resourceTag.isStudent){
                        this.classList  = []
                        this.scType = undefined
                        this.atid = undefined
                    }else{
                        this.classList = data
                        if(this.loadAccount().role == '教师' && data.length > 0){
                            let _this = this
                            //环节、类型选择状态保存功能
                            for (let k = 0; k < data.length; k++) {
                                if(data[k].Idx == _this.scType){
                                    let idArr = []
                                    data[k].ChildList.forEach((item)=>{
                                        if(item.Idx!=-1)idArr.push(item.Idx)
                                    })
                                    if(idArr.indexOf(_this.atid) == -1 && typeof(_this.atid) != 'object'){
                                        _this.atid = data[k].ChildList[1].Idx
                                    }
                                    if(typeof(_this.atid) == 'object'){
                                        _this.atid = idArr
                                    }
                                    break
                                }else if(k == data.length-1){
                                    _this.scType = data[0].Idx
                                    _this.atid = data[0].ChildList[1].Idx
                                }
                            }
                        }
                    }
                    this.getList()
                }
            })
        },
        getTransitionList(){
            let that = this
            return new Promise((resolve,reject)=>{
                let zoneId = this.$route.path == "/Res/Tbzy" ? 0 : this.getSessionVal("sc_zone");
                let api = `/api/TextBook/GetSystemTextBookUnitList?PeriodID=${this.getSunAccount('sc_period')}&SubjectID=${this.getSunAccount('sc_subject')}&ZoneID=${zoneId}`;
                if (this.$route.path == "/Res/Tbzy") api += "&CatalogType=0";
                if(this.loadAccount().role == '学生'){
                    api = `/api/TextBook/GetSystemTextBookUnitList?PeriodID=${this.getSunAccount('sc_period')}&ZoneID=${this.getSessionVal("sc_zone")}`
                }
                // 没有册次目录显示不出来
                this.startLoading()
                this.$http.get(this.getMasterDataCenterUrl() + api).then(res=>{
                    this.stopLoading()
                    if(res.data.data){
                        let data = res.data.data
                        if(this.isValidArray(data)){
                            for (let i = 0; i < data.length; i++) {
                                data[i] = this.toUpperCase(data[i])
                                for (let j = 0; j < data[i].ChildList.length; j++) {
                                    let element = data[i].ChildList[j];
                                    element = this.toUpperCase(element)
                                    for (let k = 0; k < element.ChildList.length; k++) {
                                        let element2 = element.ChildList[k];
                                        element2 = this.toUpperCase(element2)
                                    }
                                }
                            }
                            this.transitionList = data
                            //初始课后作业说明
                            let versionIndexFlag,modelIndexFlag,ceciIndexFlag
                            versionIndexFlag = this.checkVersionData(this.parseData('versionIndex'),this.transitionList)
                            if(this.isValid(versionIndexFlag)){
                                modelIndexFlag = this.checkVersionData(this.parseData('modelIndex'),this.transitionList[versionIndexFlag].ChildList)
                                if(this.isValid(modelIndexFlag)){
                                    ceciIndexFlag = this.checkVersionData(this.parseData('ceciIndex'),this.transitionList[versionIndexFlag].ChildList[modelIndexFlag].ChildList)
                                }
                            }
                            if(this.isValid(versionIndexFlag)&&this.isValid(modelIndexFlag)&&this.isValid(ceciIndexFlag)){
                                that.describe = that.transitionList[versionIndexFlag].ChildList[modelIndexFlag].ChildList[ceciIndexFlag].Describe
                            }else{
                                that.describe = that.transitionList[0].ChildList[0].ChildList[0].Describe
                            }
                            this.getMenuList().then((menuList)=>{
                                resolve(menuList)
                            })
                        }
                    }
                })
            })
        },
        getMenuList(bookId,from){
            this.bookId = bookId
            let that = this
            return new Promise((resolve,reject)=>{
                let id = bookId || this.transitionList[0].ChildList[0].ChildList[0].Idx
                let versionIndexFlag,modelIndexFlag,ceciIndexFlag
                versionIndexFlag = this.checkVersionData(this.parseData('versionIndex'),this.transitionList)
                if(versionIndexFlag!=undefined){
                    modelIndexFlag = this.checkVersionData(this.parseData('modelIndex'),this.transitionList[versionIndexFlag].ChildList)
                    if(modelIndexFlag!=undefined){
                        ceciIndexFlag = this.checkVersionData(this.parseData('ceciIndex'),this.transitionList[versionIndexFlag].ChildList[modelIndexFlag].ChildList)
                    }
                }
                if(versionIndexFlag!=undefined&&modelIndexFlag!=undefined&&ceciIndexFlag!=undefined&&this.loadAccount().role == '教师'){
                    id = this.transitionList[versionIndexFlag].ChildList[modelIndexFlag].ChildList[ceciIndexFlag].Idx
                }
                let api = '/api/TextBookUnit?BookID=' + id
                this.startLoading()    
                this.$http.get(this.getMasterDataCenterUrl()+api).then(res=>{
                    this.stopLoading()
                    if(this.isValidArray(res.data.data)){
                        let data = res.data.data
                        this.convertBook(data);

                        this.menuList = null
                        this.menuList = data
                        this.$nextTick(()=>{
                            this.$refs.sunMenu.noData = false
                        })
                        resolve(this.menuList)
                    }else{
                        this.menuList = [{
                            ChildList:[],
                            Idx:null,
                            IsBigUnit:null,
                            Name:null,
                            Prop:null,
                            TIdx:null,
                            ULevel:null,
                            UOrder:null,
                        }]
                        this.$nextTick(()=>{
                            this.$refs.sunMenu.noData = true
                        })
                        this.initList()
                    }
                })
            })
        },
        initList(){//重置资源列表
            this.list = [
                    {Total:0},
                    {Value:0}
                ]
            this.pageParam.pageIndex = 1
        },
        getSegment(){ //学生获取环节类型
            let periodId = this.getSunAccount('sc_period')
            let api = '/api/Segment/GetSegmentItemList?ScType=5&PeriodID=' + periodId
            this.$http.get(this.getMasterDataCenterUrl()+api).then(res=>{
                if(res.data.data){
                    let data = res.data.data
                    for (let i = 0; i < data.length; i++) {
                        data[i] = this.toUpperCase(data[i])
                        for (let j = 0; j < data[i].ChildList.length; j++) {
                            const element = data[i].ChildList[j];
                            element = this.toUpperCase(element)
                        }
                    }
                    this.classList = data
                }
            })
        },
        //检查记忆的目录id是否存在
        checkLocalData(idList){
            let str = JSON.stringify(this.menuList)
            for (let i = 0; i < idList.length; i++) {
               const element = idList[i];
               if(str.indexOf(element) == -1){
                   return [this.menuList[0].Idx]
               }
            }
            return idList
        },
        updateClassId(classId){
            this.searchKeywords = ''
            this.scType = classId.ScType
            this.atid = classId.ATID
            this.atItemIdx = classId.atItemIdx
            this.atid!=-1||this.scType==3?this.classIsLeaf = true:this.classIsLeaf = false
            this.initList()
            this.menuIdArr = this.checkLocalData(this.parseData('menu_idList'))
            let nodeData = this.parseData('menu_nodeData')
            if(nodeData){
                this.isBigUnit = nodeData.item.IsBigUnit
                if(!this.isBigUnit&&this.loadAccount().role == '教师'){
                    // this.scType = 1
                }else{
                    this.scType = 7
                    this.bigTitle = nodeData.item.Name
                }
                if(!this.isBigUnit&&this.loadAccount().role == '学生'){
                    this.scType = 5
                }
                if(nodeData.item.ChildList.length == 0){
                    this.menuIsLeaf = true
                }else{
                    this.menuIsLeaf = false
                }
            }
            if(classId.init)return //初始化不调列表接口
            this.getList(4)
            this.setUserOperation()
        },
        getList(e){
            let api = this.getResourceCenterBusinessUrl() + '/api/CourseCenterService/GetCourseCentersList'
            let atid = 0
            let sortList = {isRecommendation: "desc",orderCode: "asc"}
            if(this.classIsLeaf&&this.menuIsLeaf){
                sortList = {orderCode: "asc"}
            }
            if(this.isBigUnit){
                atid = undefined
            }else{
                atid = this.atid>0||this.isValidArray(this.atid)?this.atid:undefined
            }
            let param = {
                resourceDataName:'',
                resourceTag:[],
                pageIndex:this.pageParam.pageIndex,
                pageSize:this.pageParam.pageSize,
                sortList:sortList,
            }
            let resourceTagItem = {}
            let sCRIdx = { //上架相关新参数
                mustOr: [
                    {
                        buildFilterType: "eq",
                        buildFilterVale: 0
                    },    
                    {
                        buildFilterType: "eq",
                        buildFilterVale: 1
                    }, 
                    {
                        buildFilterType: "notexists",
                        buildFilterVale: 1
                    }
                ]
            }
            if(this.isValidArray(this.menuIdArr)){
                for(var i=0;i<this.menuIdArr.length;i++){
                    resourceTagItem[`unitID${i+1}`]= this.menuIdArr[i]
                    resourceTagItem['sCRIdx'] = sCRIdx
                }
            }else{
                resourceTagItem[`unitID1`] = this.menuIdArr[0]
                resourceTagItem['sCRIdx'] = sCRIdx
            }
            resourceTagItem.isStudent = this.loadAccount().role == '学生' 
            resourceTagItem.atItemIdx = this.atItemIdx>0?this.atItemIdx:undefined
            resourceTagItem.resourceDataSource = '1' 
            let rtid = [2,3]
            for (let i = 0; i < rtid.length; i++) {
                const element1 = rtid[i];
                if(this.isValidArray(atid)){
                    for (let j = 0; j < atid.length; j++) {
                        const element2 = atid[j];
                        let obj = Object.assign({},resourceTagItem, {atid: element2, rtid:element1, shareID: this.shareID})
                        param.resourceTag.push(obj)
                    }
                }else if(typeof atid == 'number'){
                    let obj = Object.assign({},resourceTagItem, {rtid:element1, shareID: this.shareID})
                    obj.atid = atid
                    param.resourceTag.push(obj)
                }else if(atid == undefined){
                    let obj = Object.assign({},resourceTagItem, {rtid:element1, shareID: this.shareID})
                    param.resourceTag.push(obj)
                }
            }
            if(this.searchKeywords.length>0){
                param = {
                    resourceDataName:this.searchKeywords,
                    pageIndex:this.pageParam.pageIndex,
                    pageSize:this.pageParam.pageSize,
                    sort:"orderCode",
                    sortType:"asc"
                }
            }
            this.startLoading()
            this.$http.post(api,param).then(res=>{
                this.stopLoading()
                let list = []
                let total = {Total:0}
                let value = {Value:0}
                list.push(total,value)
                if(res.data.data){
                    let data = res.data.data
                    data = this.toUpperCase(data)
                    for (let i = 0; i < data.ResourceData.length; i++) {
                        data.ResourceData[i] = this.toUpperCase(data.ResourceData[i])
                    }
                    list[0] = {Total:data.Total}
                    list[1] = {Value:data.PageIndex}
                    this.totalPage = data.TotalPage
                    data.ResourceData.forEach(element => {
                        let obj = {
                            Name:element.ResourceDataName,
                            Idx:element.ResourceDataId,
                            IsCollected:element.IsCollected,
                            IsCandidated:element.IsCandidated,
                            IsExcellent:element.IsExcellent,
                            Url:element.Url,
                            TypeIdx:element.Rtid,
                            Summary:element.Summary,
                            Atid:element.Atid
                        }
                        list.push(obj)
                    });
                    if(this.$refs.resList){
                        //清空搜索框
                        this.$refs.resList.$refs.searchInput.value = ''
                    }
                }
                if(this.pageParam.pageIndex>1){
                    let newList = list.slice(2)
                    this.list = this.list.concat(newList)
                }else{
                    this.list = list
                }
            })
        },
        convertBook(list) {
            for (let i = 0; i < list.length; i++) {
                const element = this.toUpperCase(list[i]), child = element.ChildList;
                this.convertBook(child);
            }
        },
        isChildLeaf(list) {
            let result = true;

            for (let i = 0; i < list.length; i++) {
                if (list[i].childList != 0) {
                    result = false;
                    break;
                }
            }

            return result;
        },
        clickJx(bool) {//是否是精品
            // this.data.isSuperior = bool
            // this.getList()
        },
        clickCrumb(id) {//面包屑
            // this.data.order = id
            // this.getList()
        },
        openDetail(data) {
            this.details = data
            this.detailStatus = true
            this.$store.commit('OPEN_SHADOW')
        },
        changePage(index) { //页码
            this.pageParam.pageIndex = index
            this.getList(1)
        },
        search(keywords) { //搜索框
            if(this.searchKeywords != 'keywords'){
                this.list = [
                    {Total:0},
                    {Value:0}
                ]
            }
            this.searchKeywords = keywords
            this.getList()
        },
        deleteList(){
            this.getList()
        },
        deleteCandidateSuccess() {
            this.getList()
        },
        onCollectedClick(){
            this.getList()  
        },
        getCrumbs(crumbs){
            this.crumbs = crumbs
        },
        onScrollBottom(){
            if(this.pageParam.pageIndex == this.totalPage){
                return
            }
            this.pageParam.pageIndex++
            this.getList(2)
        },
        onClickCloseDetails(){//关闭资源预览
            this.detailStatus  = false
            this.$store.commit('CLOSE_SHADOW')
        },
        onMenuChanged(){//选中目录发生改变时重置数据
            this.menuIdArr = this.checkLocalData(this.parseData('menu_idList'))
            let nodeData = this.parseData('menu_nodeData')
            if(nodeData){
                this.isBigUnit = nodeData.item.IsBigUnit
                if(!this.isBigUnit&&this.loadAccount().role == '教师'){
                    // this.scType = 1
                    localStorage.isBigUnit = 0
                }else{
                    this.scType = 7
                    this.bigTitle = nodeData.item.Name
                    localStorage.isBigUnit = 1
                }
                if(!this.isBigUnit&&this.loadAccount().role == '学生'){
                    this.scType = 5
                }
                if(nodeData.item.ChildList.length == 0){
                    this.menuIsLeaf = true
                }else{
                    this.menuIsLeaf = false
                }
                // this.atid = this.classList[0].ChildList[1].Idx,
            }
            this.searchKeywords = ''
            this.initList()
            if(this.loadAccount().role != '学生'){
                let param = this.getParam()
                param.resourceTag.forEach(item=>{
                    item.atid = undefined;
                    item.shareID = this.shareID;
                })
                let zoneId = this.$route.path == "/Res/Tbzy" ? 0 : this.getSessionVal("sc_zone");
                param.segmentFilter = {
                    header:{session:this.getSession()},
                    parameter:{PeriodID:this.getSunAccount('sc_period'), zoneId: zoneId }
                }
                this.getNewSegment(param)
            }else{
                this.getList(3)
            }
            this.setUserOperation()
        },
        //使用用户操作记录
        getUserOperation(){
            return new Promise((resolve,reject)=>{
                let api = '/api/UserOperationService/UserOperationGet'
                let param = {
                    "mark": 'tbkc'
                }
                this.$http.post(this.getResourceCenterBusinessUrl()+api,param).then(res=>{
                    if(res.data.status.code == 200&&this.isValidArray(res.data.data)){
                        if(res.data.data[0].UserOperation){
                            let data = res.data.data[0].UserOperation
                            for (const key in data) {
                                localStorage[key] = JSON.stringify(data[key])
                            }
                        }
                    }else{
                        console.log('UserOperationGet Error');
                    }
                    resolve(res.data.data)
                })
            })
        },
        //记录用户操作
        setUserOperation(){
            let api = '/api/UserOperationService/UserOperationSet'
            let userOperation = {
                versionIndex: this.parseData('versionIndex')||0,
                modelIndex: this.parseData('modelIndex') ||0,
                ceciIndex: this.parseData('ceciIndex') ||0,
                menu_checked:this.parseData('menu_checked') ||undefined,
                menu_idList:this.parseData('menu_idList') ||undefined,
                menu_opened:this.parseData('menu_opened') ||undefined,
                menu_nodeData:this.parseData('menu_nodeData') ||undefined
            }
            let param = {
                "userOperation": userOperation,
                "mark": 'tbkc'
            }
            this.$http.post(this.getResourceCenterBusinessUrl()+api,param).then(res=>{
                if(res.data.status.code == 200){

                }else{
                    console.log('UserOperationSet Error');
                }
            })
        },
        parseData(key){
            let data = localStorage[key]
            if(data){
                return JSON.parse(data)
            }else{
                return false
            }
        },
        updateDescribe(data){
            this.describe = data
        },
        //检查记忆的数据是否合法
        checkVersionData(data,list){
            if(data&&data<list.length){
                return data
            }else{
                return 0
            }
        },
        getParam(){
            let atid = 0
            if(this.isBigUnit){
                atid = undefined
            }else{
                atid = this.atid>0||this.isValidArray(this.atid)?this.atid:undefined
            }
            let param = {
                resourceTag:[],
            }
            let resourceTagItem = {}
            let sCRIdx = { //上架相关新参数
                mustOr: [
                    {
                        buildFilterType: "eq",
                        buildFilterVale: 0
                    },
                    {
                        buildFilterType: "eq",
                        buildFilterVale: 1
                    }, {
                        buildFilterType: "notexists",
                        buildFilterVale: 1
                    }
                ]
            }
            if(this.isValidArray(this.menuIdArr)){
                for(var i=0;i<this.menuIdArr.length;i++){
                    resourceTagItem[`unitID${i+1}`]= this.menuIdArr[i]
                    resourceTagItem['sCRIdx'] = sCRIdx
                }
            }else{
                resourceTagItem[`unitID1`] = this.menuIdArr[0]
                resourceTagItem['sCRIdx'] = sCRIdx
            }
            resourceTagItem.isStudent = this.loadAccount().role == '学生' 
            resourceTagItem.resourceDataSource = '1' 
            let rtid = [2,3]
            for (let i = 0; i < rtid.length; i++) {
                const element1 = rtid[i];
                if(this.isValidArray(atid)){
                    for (let j = 0; j < atid.length; j++) {
                        const element2 = atid[j];
                        let obj = Object.assign({},resourceTagItem, {atid: element2, rtid:element1})
                        param.resourceTag.push(obj)
                    }
                }else if(typeof atid == 'number'){
                    let obj = Object.assign({},resourceTagItem, {rtid:element1})
                    obj.atid = atid
                    param.resourceTag.push(obj)
                }else if(atid == undefined){
                    let obj = Object.assign({},resourceTagItem, {rtid:element1})
                    param.resourceTag.push(obj)
                }
            }
            return param
        }
  },
  
}
</script>

<style scoped>
    .TbkcIndex{
        min-height: 100vh !important;
        padding-left: 95px;
        overflow: hidden;
    }
    .res-box{
        position: relative;
        display: flex;
        justify-content: space-between;
        /* margin-bottom: 40px; */
    }
    .menu{
        /* width: 23%; */
        max-height: 100vh;
        min-height: 100vh;
        background: #FFF;
        max-width: 410px;
        /* width: calc(100% - 6px); */
        /* overflow: auto; */
    }
    /* 隐藏目录滚动条 */
    .menu::-webkit-scrollbar { width: 0 !important }
    .content{
        position: relative;
        width: calc(100% - 300px);
        /* overflow: auto; */
        margin-left: 15px;
    }

    .Lecture-Details{
        position: absolute;
        width: 84%;
        height: 100%;
        left: 8%;
        top: 20px;
        z-index: 999;
    }
    .Preview-Details{
        position: absolute;
        width: 90%;
        top: 5%;
        left: 50%;
        height: 90%;
        transform: translateX(-50%);
        z-index: 999;
    }
    .Preview-Details >>> .close{
        opacity:1;
    }
    .chapter-introduce{
        border: 1px solid #D4DFE7;
        margin-bottom: 10px;
        font-size: 20px;
        padding: 0px 20px;
        height: 50px;
        line-height: 50px;
        box-sizing:border-box;
        -moz-box-sizing:border-box;
        -webkit-box-sizing:border-box;
        color: #fff;
        text-align: center;
        background: #0EBAD7;
        cursor: pointer;
    }
    .chapter-introduce:hover{
        background: #57a3f3;
    }
    .upload-custom-icon{
        background: url(/static/images/console/upload_hand.png);
        width: 23px;
        height: 24px;
        background-size: 46px 24px;
        background-position: -23px 0;
        display: inline-block;
        margin-right: 2px;
        position: relative;
        top: 4px;
    }
    .icon-list /deep/ .el-button.is-round {
        border-radius: 20px;
        padding: 9px 22px;
    }
    .icon-list /deep/ .guidance{
        background: #66BBFF;
        color: #edf3f7;
    }
    .icon-list /deep/ .application{
        background: #58CADB;
        color: #edf3f7;
    }
    .sun-res-list-box{
        /* height: 100%; */
    }
    .scrollbar{
        height:100%;
        min-width:300px
    }
    .bigTitle{
        height: 86px;
        background: #FFF;
        font-size: 20px;
        color: #4A4A4A;
        line-height: 86px;
        padding-left: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .no-result-img{
        margin: 80px auto;
        display: block;
        width: 100%;
        max-width: 450px;
    }
    .res-box /deep/ .el-scrollbar__bar{
        display: none;
    }
    .res-box /deep/.el-scrollbar__wrap {
        /* overflow-x: hidden; */
        height: 92% !important;
    }
    .hideNav{
        padding-left: 15px;
    }
    
</style>